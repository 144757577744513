interface ViolenceLevelInterface {
  name: string;
  color: string;
  padding: string;
}

export const violenceLevels: ViolenceLevelInterface[] = [
  { name: "None", color: "#d4d4d4", padding: "5px 10px" },
  { name: "Mild", color: "#cc9999", padding: "5px 10px" },
  { name: "Moderate", color: "#b87777", padding: "5px 10px" },
  { name: "Severe", color: "#b25252", padding: "5px 10px" },
  { name: "Graphic", color: "#963838", padding: "5px 10px" },
  { name: "Extremely Graphic", color: "#7a2020", padding: "5px 10px" },
];
